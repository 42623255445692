import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectSpecialties, selectUploadFileFilters, setUploadFileFilters } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';
import { QUEUE_STATUS } from '../../utils/constants/upload';
import { fetchSpecialties } from '../../utils/helpers-api';
import Actions from './Actions';
import CollapsibleRow from './CollapsibleRow';
import QueueFilter from './QueueFilter';
import SearchFilter from './SearchFilter';

export default function UploadDetail() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = useSelector(selectUserType);
  const ingestionId = location.pathname.split('/').pop();
  const { getAccessTokenSilently } = useAuth0();
  const specialties = useSelector(selectSpecialties);
  const filters = useSelector(selectUploadFileFilters);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [patients, setPatients] = useState({
    filename: '',
    ready: [],
    pending: [],
    unfit: [],
    sent: [],
  });
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const rowsPerSection = 25;
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  useEffect(() => {
    if (userType !== USER_ROLES.ADMIN) {
      navigate('/dashboard');
    }

    if (isEmpty(specialties)) {
      getSpecialties();
    }

    if (isEmpty(patients.fileName)) {
      getUpload();
    }

    getPageCount();
  }, [filters.queue, patients, specialties]);

  const getSpecialties = async () => {
    const token = await getAccessTokenSilently();

    await fetchSpecialties(token);
  };

  const getUpload = async () => {
    try {
      const token = await getAccessTokenSilently();
      const patients = await HiroApi.getUpload(ingestionId, token);

      setPatients(patients);
      getPageCount();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateField = async (ingestionId, patientId, patient) => {
    try {
      const token = await getAccessTokenSilently();

      await HiroApi.updateIngestionRow(ingestionId, patientId, patient, token);
    } catch (error) {
      console.log(error);
    }
  };

  const updateQueue = async (queue) => {
    if (isValid(queue, selectedPatients)) {
      try {
        setActionLoading(true);

        const token = await getAccessTokenSilently();
        const payload = selectedPatients.map((patient) => {
          return { id: patient.id, queue };
        });

        await HiroApi.updateQueue(ingestionId, payload, token);
        await getUpload();
        setSelectedPatients([]);
        setActionLoading(false);
      } catch (error) {
        console.log(error);
        setActionLoading(false);
      }
    }
  };

  const isValid = (queue, selected) => {
    let isValid = true;

    if (queue === QUEUE_STATUS.READY || queue === QUEUE_STATUS.SENT) {
      selected.forEach((patient) => {
        patient.referrals.forEach((referral) => {
          const { providerId, followupSpecialtyId } = referral;

          if (isEmpty(providerId) !== isEmpty(followupSpecialtyId)) {
            isValid = false;
          }
        });
      });
    }

    if (!isValid) {
      setShowValidationMessage(true);
      setTimeout(() => setShowValidationMessage(false), 1500);
    }

    return isValid;
  };

  const selectPatient = (selectedPatient, selectedList) => {
    const isIdInArray = selectedList.some((patient) => patient.id === selectedPatient.id);

    if (isIdInArray) {
      const filtered = selectedList.filter((patient) => patient.id !== selectedPatient.id);

      setSelectedPatients(filtered);
    } else {
      setSelectedPatients([...selectedList, selectedPatient]);
    }
  };

  const isChecked = (id, selectedList) => {
    return selectedList.some((patient) => patient.id === id);
  };

  const search = (searchTerm) => {
    dispatch(setUploadFileFilters({ queue: 'all', search: searchTerm }));
  };

  const getPageCount = () => {
    if (filters.queue === 'all') {
      const allQueueLengths =
        patients.ready.length + patients.pending.length + patients.sent.length + patients.unfit.length;

      setPageCount(Math.ceil(allQueueLengths / rowsPerSection));
    } else {
      setPageCount(Math.ceil(patients[filters.queue].length / rowsPerSection));
    }
  };

  const changePage = (event, pageNumber) => {
    setPage(pageNumber);

    if (filters.queue === 'all') {
      getInGroups(groupAllPatients(), pageNumber);
    } else {
      getInGroups(patients[filters.queue], pageNumber);
    }
  };

  const groupAllPatients = () => {
    const all = [...patients.ready, ...patients.pending, ...patients.sent, ...patients.unfit];

    if (!isEmpty(filters.search)) {
      return all.filter((patient) => patient.name.toLowerCase().includes(filters.search.toLowerCase()));
    }

    return all;
  };

  const getInGroups = (patients, page) => {
    const start = (page - 1) * rowsPerSection;
    const end = start + rowsPerSection;

    return patients.slice(start, end);
  };

  return loading ? (
    <div className="upload-detail loading">
      <Loader />
    </div>
  ) : (
    <div className="upload-detail">
      <h1>Discharge List</h1>

      <div className="upload-detail-header">
        <p>Filename:</p>
        <span>{patients.fileName || ''}</span>
      </div>

      <div className="upload-detail-filters">
        <QueueFilter setPage={setPage} />
        <SearchFilter search={search} />
        <Actions actionLoading={actionLoading} selectedPatients={selectedPatients} updateQueue={updateQueue} />
        {showValidationMessage ? <Alert severity="error">Selected rows not complete</Alert> : null}
      </div>

      <div className="upload-detail-content">
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">DOB</TableCell>
                <TableCell align="center">Phone</TableCell>
                <TableCell align="center">PCP</TableCell>
                <TableCell align="center">Follow Up Provider</TableCell>
                <TableCell align="center">Specialty</TableCell>
                <TableCell align="center">Disqualifiers</TableCell>
                <TableCell align="center">EHR</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filters.queue === 'all'
                ? getInGroups(groupAllPatients(), page).map((patient) => (
                    <CollapsibleRow
                      key={patient.id}
                      patient={patient}
                      patients={patients}
                      ingestionId={ingestionId}
                      isChecked={isChecked}
                      updateField={updateField}
                      setPatients={setPatients}
                      selectPatient={selectPatient}
                      selectedPatients={selectedPatients}
                    />
                  ))
                : null}

              {filters.queue === QUEUE_STATUS.READY
                ? getInGroups(patients.ready, page).map((patient) => (
                    <CollapsibleRow
                      key={patient.id}
                      patient={patient}
                      patients={patients}
                      ingestionId={ingestionId}
                      isChecked={isChecked}
                      updateField={updateField}
                      setPatients={setPatients}
                      selectPatient={selectPatient}
                      selectedPatients={selectedPatients}
                    />
                  ))
                : null}

              {filters.queue === QUEUE_STATUS.PENDING
                ? getInGroups(patients.pending, page).map((patient) => (
                    <CollapsibleRow
                      key={patient.id}
                      patient={patient}
                      patients={patients}
                      ingestionId={ingestionId}
                      isChecked={isChecked}
                      updateField={updateField}
                      setPatients={setPatients}
                      selectPatient={selectPatient}
                      selectedPatients={selectedPatients}
                    />
                  ))
                : null}

              {filters.queue === QUEUE_STATUS.SENT
                ? getInGroups(patients.sent, page).map((patient) => (
                    <CollapsibleRow
                      key={patient.id}
                      patient={patient}
                      patients={patients}
                      ingestionId={ingestionId}
                      isChecked={isChecked}
                      updateField={updateField}
                      setPatients={setPatients}
                      selectPatient={selectPatient}
                      selectedPatients={selectedPatients}
                    />
                  ))
                : null}

              {filters.queue === QUEUE_STATUS.UNFIT
                ? getInGroups(patients.unfit, page).map((patient) => (
                    <CollapsibleRow
                      key={patient.id}
                      patient={patient}
                      patients={patients}
                      ingestionId={ingestionId}
                      isChecked={isChecked}
                      updateField={updateField}
                      setPatients={setPatients}
                      selectPatient={selectPatient}
                      selectedPatients={selectedPatients}
                    />
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination count={pageCount} variant="outlined" page={page} onChange={changePage} boundaryCount={10} />
      </div>
    </div>
  );
}
