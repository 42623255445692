import axios from 'axios';

import { MAX_QUERY_LIMIT } from './utils/constants/system';

const BASE_URL = process.env.REACT_APP_API_HOST || '';

const createArrayQuery = (key, arr) => {
  if (arr.length === 1) {
    return `${key}[]=${arr[0]}&`;
  }

  if (arr.length > 1) {
    let query = '';

    arr.forEach((code) => (query = query.concat(`${key}=${code}&`)));

    return query;
  }

  return '';
};

export default class BaymaxApi {
  static facility = {};

  static buildUrl(endpoint, facilityNotRequired) {
    let url = '';

    if (facilityNotRequired) {
      url = `${BASE_URL}/api/v1/${endpoint}`;
    } else {
      url = `${BASE_URL}/api/v1/facility/${this.facility.id}/${endpoint}`;
    }

    return url;
  }

  static async request(endpoint, paramsOrData = {}, verb = 'get', token = '', facilityNotRequired = false) {
    try {
      return (
        await axios({
          method: verb,
          url: this.buildUrl(endpoint, facilityNotRequired),
          [verb === 'get' ? 'params' : 'data']: paramsOrData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ).data;
    } catch (err) {
      if (Array.isArray(err.response.data.message)) {
        throw err.response.data.message;
      } else {
        throw [err.response.data.message];
      }
    }
  }

  // GET /facility
  static async getFacilities(token) {
    return this.request('facility', {}, 'GET', token, true);
  }

  // GET /providers
  static async getProviders(specialty, departments, token) {
    if (specialty && departments) {
      const departmentsQuery = createArrayQuery('dept', departments);

      return this.request(`providers?specialty=${specialty}&${departmentsQuery}active=true`, {}, 'GET', token);
    }

    return this.request('providers?active=true', {}, 'GET', token);
  }

  // GET /providers/specialties
  static async getSpecialties(token) {
    return this.request('providers/specialties', {}, 'GET', token);
  }

  // GET /departments
  static async getDepartments(specialtyId, token) {
    return this.request(`departments?specialty=${specialtyId}`, {}, 'GET', token);
  }

  // GET /campaigns
  static async getCampaigns(filters, token) {
    const { query, startDate, endDate, providers, queue } = filters;
    const pcp = providers.map((provider) => provider.id);
    const pcpQuery = createArrayQuery('pcp', pcp);
    const queryString = `campaigns?${pcpQuery}&startDate=${startDate}&endDate=${endDate}&query=${query}&queue=${queue}`;

    if (queryString.length > MAX_QUERY_LIMIT) {
      throw new Error('Too many filters applied.');
    }

    return this.request(queryString, {}, 'GET', token);
  }

  // POST /campaigns/:id/opt-out/call
  static async optOutCall(id, token) {
    return this.request(`campaigns/${id}/opt-out/call`, {}, 'POST', token);
  }

  // PATCH /patients/:id
  static async updatePatient(id, payload, token) {
    return this.request(`patients/${id}`, payload, 'PATCH', token);
  }

  // GET /patients/:id
  static async getPatient(id, token) {
    return this.request(`patients/${id}`, {}, 'GET', token);
  }

  // GET /patients/:id/discharges
  static async getPatientDischarges(id, token) {
    return this.request(`patients/${id}/discharges`, {}, 'GET', token);
  }

  // GET campaigns/:id/appointments
  static async getFutureAppointments(id, token) {
    return this.request(`campaigns/${id}/appointments`, {}, 'GET', token);
  }

  // GET campaigns/:id/appointments/past
  static async getPastAppointments(id, token) {
    return this.request(`campaigns/${id}/appointments/past`, {}, 'GET', token);
  }

  // GET patients/:id/appointments
  static async getFuturePatientAppointments(id, token) {
    return this.request(`patients/${id}/appointments`, {}, 'GET', token);
  }

  // GET patients/:id/appointments/past
  static async getPastPatientAppointments(id, token) {
    return this.request(`patients/${id}/appointments/past`, {}, 'GET', token);
  }

  // POST /patients/search
  static async findEhrRecord(payload, token) {
    return this.request('patients/search', payload, 'POST', token);
  }

  // POST /patients/:id/wrong-number
  static async addWrongNumber(patientId, payload, token) {
    // payload should be { phoneNumber: string (10 digits with or without country code, no dashes or parens) }
    return this.request(`patients/${patientId}/wrong-number`, payload, 'POST', token);
  }

  // GET /appointments
  static async getPatientAppointments(patientId, token) {
    return this.request(`appointments?patientId=${patientId}`, {}, 'GET', token);
  }

  // GET /appointments
  static async getCampaignAppointments(campaignId, token) {
    return this.request(`appointments?campaignId=${campaignId}`, {}, 'GET', token);
  }

  // GET /appointments/slots
  static async getAppointmentSlots(providers, departments, apptType, startDate, token) {
    const providerIds = providers.map((provider) => provider.id);
    const providerQuery = createArrayQuery('providers', providerIds);
    const departmentQuery = createArrayQuery('dept', departments);

    return this.request(
      `appointments/slots?${providerQuery}${departmentQuery}appointmentType=${apptType}&startDate=${startDate}`,
      {},
      'GET',
      token
    );
  }

  // POST /appointments/slots/verify
  static async verifyAppointment(payload, token) {
    return this.request('appointments/slots/verify', payload, 'POST', token);
  }

  // POST /appointments
  static async bookAppointment(payload, token) {
    return this.request('appointments', payload, 'POST', token);
  }

  // PUT /appointments/:appointmentId
  static async cancelPatientAppointment(appointmentId, payload, token) {
    return this.request(`appointments/${appointmentId}`, payload, 'PUT', token);
  }

  // GET campaign/:campaignId
  static async getPatientCampaign(campaignId, token) {
    return this.request(`campaigns/${campaignId}`, {}, 'GET', token);
  }

  // POST campaign/:campaignId
  static async sendMessageToCampaign(campaignId, message, token) {
    return this.request(`campaigns/${campaignId}`, { message }, 'POST', token);
  }

  // POST campaign/:campaignId/status/:referralId
  static async updateReferralStatus(campaignId, referralId, payload, token) {
    return this.request(`campaigns/${campaignId}/status/${referralId}`, payload, 'POST', token);
  }

  // PATCH campaign/:campaignId
  static async updateCampaign(campaignId, payload, token) {
    return this.request(`campaigns/${campaignId}`, payload, 'PATCH', token);
  }

  // POST campaign/:campaignId/recommended
  static async getRecommendedMessage(campaignId, messageId, token) {
    return this.request(`campaigns/${campaignId}/recommended`, { messageId }, 'POST', token);
  }

  // GET campaign/:campaignId/recommended
  static async getRecommendedMessages(campaignId, messageId, token) {
    return this.request(`campaigns/${campaignId}/recommended?messageId=${messageId}`, {}, 'GET', token);
  }

  // PATCH campaign/:campaignId/recommended/:recommendedId
  static async recommendationFeedback(campaignId, recommendedId, feedback, token) {
    return this.request(`campaigns/${campaignId}/recommended/${recommendedId}`, { feedback }, 'PATCH', token);
  }

  // GET /ingestion
  static async getUpload(id, token) {
    return this.request(`ingestions/${id}`, {}, 'GET', token);
  }

  // GET /ingestions
  static async getUploads(payload, token) {
    const { startDate, endDate } = payload;

    return this.request(`ingestions?startDate=${startDate}&endDate=${endDate}`, {}, 'GET', token);
  }

  // POST /ingestions
  static async uploadPatients(payload, token) {
    return this.request(`ingestions`, payload, 'POST', token);
  }

  // PUT /ingestions/:id/queue
  static async updateQueue(ingestionId, payload, token) {
    return this.request(`ingestions/${ingestionId}/queue`, payload, 'PUT', token);
  }

  // PATCH /ingestions/:id/patient/:patientId
  static async updateIngestionRow(ingestionId, patientId, payload, token) {
    return this.request(`ingestions/${ingestionId}/patient/${patientId}`, payload, 'PATCH', token);
  }

  // GET analytics
  static async getAnalytics(chartName, token) {
    // const queryString = `patients?${pcpQuery}&startDate=${startDate}&endDate=${endDate}&query=${query}&queue=${queue}`;
    return this.request(`analytics?chart=${chartName}`, {}, 'GET', token);
  }

  // POST /translation
  static async translateMessage(payload, token) {
    return this.request(`translation`, payload, 'POST', token);
  }

  // GET /settings
  static async getSettings(token) {
    return this.request('settings', {}, 'GET', token);
  }

  // POST /settings/provider-exclusion
  static async excludeProvider(name, token) {
    return this.request('settings/provider-exclusion', { name }, 'POST', token);
  }

  // POST /settings/ingestion-inclusion
  static async addInclusion(name, category, token) {
    return this.request('settings/ingestion-inclusion', { name, category }, 'POST', token);
  }

  // PUT /settings/ingestion-inclusion/:id
  static async editInclusion(inclusionId, name, category, token) {
    return this.request(`settings/ingestion-inclusion/${inclusionId}`, { name, category }, 'PUT', token);
  }

  // DELETE /settings/ingestion-inclusion/:id
  static async removeInclusion(inclusionId, token) {
    return this.request(`settings/ingestion-inclusion/${inclusionId}`, {}, 'DELETE', token);
  }
}
