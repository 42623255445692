export const CAMPAIGN_STATUSES = [
  {
    label: 'Start',
    value: 'Start',
  },
  {
    label: 'Consent Pending',
    value: 'ConsentPending',
  },
  {
    label: 'Text Ongoing',
    value: 'TextOngoing',
  },
  {
    label: 'Ready for Call',
    value: 'ReadyForCall',
  },
  {
    label: 'Other Call',
    value: 'OtherCall',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
];
