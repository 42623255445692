import './style.scss';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectSpecialties } from '../../state/systemSlice';
import { getSpecialtyInfo } from '../../utils/helpers';

export default function SpecialtyFilter(props) {
  const {
    getDepartments,
    setAppointmentSlots,
    setDepartmentFilter,
    setProviderFilter,
    setProvidersBySpecialtyAndDept,
    specialtyId,
    setSpecialtyId,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const specialties = useSelector(selectSpecialties);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const selectedSpecialty = event.target.attributes.value.value;

    if (selectedSpecialty === specialtyId) {
      setSpecialtyId('');
    } else {
      setSpecialtyId(selectedSpecialty);
    }

    setAppointmentSlots([]);
    setDepartmentFilter([]);
    setProviderFilter([]);
    setProvidersBySpecialtyAndDept([]);
    getDepartments(selectedSpecialty);

    handleClose();
  };

  const formatSelected = (specialtyId) => {
    if (!isEmpty(specialtyId)) {
      const specialty = getSpecialtyInfo(specialtyId);

      return <span>({specialty.name})</span>;
    }

    return '';
  };

  return (
    <div className="specialty-filter">
      <Button variant="outlined" onClick={openMenu}>
        Specialty {formatSelected(specialtyId)}
      </Button>
      <Menu className="table-filter-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {specialties.map((specialty) => (
          <MenuItem
            key={specialty.id}
            value={specialty.id}
            onClick={handleChange}
            selected={specialty.id === specialtyId}
          >
            {specialty.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
