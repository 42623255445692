import './style.scss';

import Button from '@mui/material/Button';
import React from 'react';

import Loader from '../Loader/Loader';

export default function LoadingButton({ classNames, id, isLoading, hide, label, onClick, variant }) {
  const handleClick = (event) => {
    if (!isLoading) {
      onClick(event);
    }
  };

  return (
    <div className="loading-button">
      {isLoading ? (
        hide ? null : (
          <Loader />
        )
      ) : (
        <Button id={id} className={`${classNames}`} variant={variant} onClick={handleClick} disabled={isLoading}>
          {label}
        </Button>
      )}
    </div>
  );
}
