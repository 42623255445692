import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resetSystemState, selectCurrentFacility, setShowFacilitySelect } from '../../state/systemSlice';
import { selectUserName, selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';

export default function UserDropdown() {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orgName = useSelector(selectCurrentFacility)?.name;
  const username = useSelector(selectUserName);
  const userType = useSelector(selectUserType);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchPractice = () => {
    setAnchorEl(null);
    dispatch(setShowFacilitySelect(true));
  };

  const goToSettings = () => {
    setAnchorEl(null);
    navigate('settings');
  };

  const handleLogout = () => {
    dispatch(resetSystemState());
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  return (
    <div className="user-dropdown">
      <Button onClick={handleClick}>
        <AccountCircleIcon />
        <div className="user-info">
          <Typography variant="subtitle1">{username}</Typography>
          <Typography variant="subtitle2">{orgName}</Typography>
        </div>
      </Button>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={switchPractice}>Switch Practice</MenuItem>
        {userType === USER_ROLES.ADMIN ? <MenuItem onClick={goToSettings}>Settings</MenuItem> : null}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
