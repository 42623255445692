import './style.scss';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { find, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectDepartments } from '../../state/systemSlice';

export default function DepartmentFilter(props) {
  const { getProviders, resetProviderFilter, selectedDepartments, selectDepartment, setDepartmentFilter, specialtyId } =
    props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const departments = useSelector(selectDepartments);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const departmentId = event.target.attributes.value.value;
    const department = find(departments, ['id', departmentId]);

    selectDepartment(department);
  };

  const selectAll = () => {
    if (selectedDepartments.length === departments.length) {
      setDepartmentFilter([]);
      resetProviderFilter();
    } else {
      const all = departments.map((dept) => dept.id);

      setDepartmentFilter(all);
      getProviders(specialtyId, all);
    }
  };

  const isChecked = (department) => {
    if (find(selectedDepartments, (dept) => dept === department.id)) {
      return true;
    }

    return false;
  };

  const selectedCount = (selectedDepartments) => {
    if (!isEmpty(selectedDepartments)) {
      return <span>({selectedDepartments.length})</span>;
    }

    return '';
  };

  return (
    <div className="department-filter">
      <Button variant="outlined" onClick={openMenu} disabled={departments.length < 1}>
        Department
        {selectedCount(selectedDepartments)}
      </Button>
      <Menu className="table-filter-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem className="all" onClick={selectAll}>
          Select/Deselect All
        </MenuItem>

        {departments.map((department) => (
          <MenuItem className="department" key={department.id} value={department.id} onClick={handleChange}>
            <Checkbox checked={isChecked(department)} disabled />
            <div className="info">
              <h4 value={department.id}>{department.name}</h4>
              <p value={department.id}>{department.address}</p>
              <p value={department.id}>
                {department.city}, {department.state} {department.zip}
              </p>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
