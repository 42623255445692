import './style.scss';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUploadFileFilters, setUploadFileFilters } from '../../state/systemSlice';

export default function SearchFilter(props) {
  const dispatch = useDispatch();
  const filters = useSelector(selectUploadFileFilters);
  const [query, setQuery] = useState('');

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = () => {
    props.search(query);
  };

  const clear = () => {
    setQuery('');
    dispatch(setUploadFileFilters({ search: '' }));
  };

  return (
    <div className="search-filter">
      <TextField
        placeholder="Search by Name"
        value={query}
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSubmit();
          }
        }}
        sx={{
          fieldset: { borderColor: '#20ce99' },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSubmit}>
                <SearchIcon />
              </IconButton>
              {filters.search.length ? (
                <IconButton onClick={clear}>
                  <ClearIcon />
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
