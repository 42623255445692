import './style.scss';

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';

export default function TypeToggle({ campaignType, onChange }) {
  const handleChange = (event, newType) => {
    onChange(newType);
  };

  return (
    <div className="toggle">
      <ToggleButtonGroup exclusive value={campaignType} onChange={handleChange}>
        <ToggleButton value="SMS">
          <SmsOutlinedIcon />
        </ToggleButton>
        <ToggleButton value="Call">
          <CallOutlinedIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
