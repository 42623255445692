import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectCurrentFacility } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';

export default function PhoneNumber({ campaignId, campaignNumber, getCampaign, numbers, patientId }) {
  const { getAccessTokenSilently } = useAuth0();
  const facility = useSelector(selectCurrentFacility);
  const userType = useSelector(selectUserType);
  const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const updateOptOutCall = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();

      await HiroApi.optOutCall(campaignId, token);
      await getCampaign();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addWrongNumber = async (phoneNumber) => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();

      await HiroApi.addWrongNumber(patientId, { phoneNumber }, token);
      await getCampaign();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const setCampaignNumber = async (phoneNumber) => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();

      await HiroApi.updateCampaign(campaignId, { phone: phoneNumber.substring(2) }, token);
      await getCampaign();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const dialpadHyperlink = (patientNumber) => {
    return `dialpad://${patientNumber}?fromNumber=+1${facility.dialpadNumber}&launchMinimode=0&confirmPhone=1`;
  };

  const isCampaignNumber = (phoneNumber) => {
    // Remove "+1" when comparing
    if (phoneNumber.substring(2) === campaignNumber) {
      return true;
    }

    return false;
  };

  const showSetCampaignButton = (phone) => {
    if (
      isCampaignNumber(phone.phoneNumber) ||
      phone.optOut ||
      phone.wrongNumber ||
      userType === USER_ROLES.COORDINATOR
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className="phone-numbers">
      <div className="header">
        <h3>Phone Numbers</h3>
        {loading ? (
          <Loader />
        ) : (
          <IconButton onClick={() => setShowOptions(!showOptions)}>
            <SettingsIcon />
          </IconButton>
        )}
      </div>

      {numbers
        ? numbers.map((phone) => (
            <div key={`${phone.phoneNumber}-${phone.source}`} className="phone">
              <CallOutlinedIcon />

              {phone.optOut || phone.wrongNumber ? (
                <span className={phone.optOut ? 'red' : phone.wrongNumber ? 'orange' : null}>
                  {phone.nationalFormat}
                </span>
              ) : (
                <a href={dialpadHyperlink(phone.phoneNumber)} rel="noreferrer">
                  {phone.nationalFormat}
                </a>
              )}

              <div className="chips">
                <Chip variant="outlined" className="capitalize" label={phone.lineType} />
                <Chip variant="outlined" className="capitalize" label={phone.source} />
                {isCampaignNumber(phone.phoneNumber) ? (
                  <Chip variant="outlined" className="capitalize primary-color" label="Campaign Number" />
                ) : null}
              </div>

              {showOptions && !(phone.optOut || phone.wrongNumber) ? (
                <div className="actions">
                  {isCampaignNumber(phone.phoneNumber) ? (
                    <Button variant="outlined" onClick={() => updateOptOutCall()}>
                      Opt Out
                    </Button>
                  ) : null}

                  <Button variant="outlined" onClick={() => addWrongNumber(phone.phoneNumber)}>
                    Wrong Number
                  </Button>

                  {showSetCampaignButton(phone) ? (
                    <Button variant="contained" onClick={() => setCampaignNumber(phone.phoneNumber)}>
                      Set Campaign Number
                    </Button>
                  ) : null}
                </div>
              ) : null}
            </div>
          ))
        : null}
    </div>
  );
}
