export const DISQUALIFIERS_ALL_REFERRALS = [
  'Has Active Campaign',
  'Disposition Not Supported',
  'Has Past Campaign',
  'Has Past Discharge',
  'Age Under 2 Years',
  'No Active Number',
  'Phone Number is Shared',
  'Opted Out',
];

export const DISQUALIFIERS_SINGLE_REFERRAL = [
  'Specialty Not Supported',
  'Has Existing Visit',
  'Has Provider',
  'Duplicate Discharge',
];
