import './style.scss';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

export default function AppointmentTypeFilter(props) {
  const { selectedType, setApptType } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const apptType = event.target.attributes.value.value;

    setApptType(apptType);
    handleClose();
  };

  const formatSelected = (type) => {
    if (!isEmpty(type)) {
      return <span>({type})</span>;
    }

    return '';
  };

  return (
    <div className="appointment-type-filter">
      <Button variant="outlined" onClick={openMenu}>
        Appointment Type {formatSelected(selectedType)}
      </Button>
      <Menu className="table-filter-menu appointment-type" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem value="new" onClick={handleChange} selected={selectedType === 'new'}>
          New
        </MenuItem>
        <MenuItem value="established" onClick={handleChange} selected={selectedType === 'established'}>
          Established
        </MenuItem>
      </Menu>
    </div>
  );
}
