import './style.scss';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectProviders, selectSpecialties } from '../../state/systemSlice';
import { UNASSIGNED_PROVIDER_ID } from '../../utils/constants/system';
import { findSpecialtyIdByName, getSpecialtyInfo } from '../../utils/helpers';
import Provider from './Provider';

export default function ProvidersDrawer(props) {
  const { open, setOpenDrawer } = props;
  const providers = useSelector(selectProviders);
  const specialties = useSelector(selectSpecialties);
  const [anchorEl, setAnchorEl] = useState(null);
  const filterOpen = Boolean(anchorEl);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [selectedSpecialty, setSelectedSpeciatly] = useState({});

  useEffect(() => {
    if (isEmpty(selectedSpecialty)) {
      setFilteredProviders([]);
    } else {
      setFilteredProviders(filterProviders(selectedSpecialty));
    }
  }, [selectedSpecialty]);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const selected = event.target.attributes.value.value;

    if (selected === selectedSpecialty) {
      setSelectedSpeciatly('');
    } else {
      setSelectedSpeciatly(selected);
    }

    handleClose();
  };

  const formatSelected = (specialtyId) => {
    if (!isEmpty(specialtyId)) {
      const specialty = getSpecialtyInfo(specialtyId);

      return <span>({specialty.name})</span>;
    }

    return '';
  };

  const filterProviders = (specialtyId) => {
    return providers.filter((provider) => {
      if (provider.id !== UNASSIGNED_PROVIDER_ID) {
        const specialty = findSpecialtyIdByName(provider.specialties[0]);

        if (specialty === specialtyId) {
          return true;
        }

        return false;
      }

      return false;
    });
  };

  const showProviders = () => {
    let providersToShow;

    if (!isEmpty(selectedSpecialty)) {
      providersToShow = filteredProviders;
    } else {
      providersToShow = providers;
    }

    return providersToShow.map((provider) => {
      if (provider.id !== UNASSIGNED_PROVIDER_ID) {
        return <Provider key={provider.id} provider={provider} specialty={{ name: provider.specialties[0] }} />;
      }

      return null;
    });
  };

  return (
    <Drawer anchor="right" className="provider-list" open={open} onClose={() => setOpenDrawer(false)}>
      <h2>Providers</h2>

      <div className="specialty-filter">
        <Button variant="outlined" onClick={openMenu}>
          Specialty {formatSelected(selectedSpecialty)}
        </Button>
        <Menu className="table-filter-menu" anchorEl={anchorEl} open={filterOpen} onClose={handleClose}>
          {specialties.map((specialty) => (
            <MenuItem
              key={specialty.id}
              value={specialty.id}
              onClick={handleChange}
              selected={specialty.id === selectedSpecialty}
            >
              {specialty.name}
            </MenuItem>
          ))}
        </Menu>
      </div>

      {showProviders()}
    </Drawer>
  );
}
