import './style.scss';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { formatDate, formatTime, getProviderName, getSpecialtyInfo } from '../../utils/helpers';

export default function ConfirmationModal(props) {
  const { dobConfirmationRequired, onClose, onSuccess, open, patient = {}, referrals, slot } = props;
  const [dob, setDob] = useState('');
  const [referralId, setReferralId] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (!isEmpty(slot.notes)) {
      setNotes(slot.notes);
    }

    if (referrals.length === 1) {
      setReferralId(referrals[0].id);
    }
  }, [slot]);

  const confirm = () => {
    slot.referralId = referralId;
    onSuccess(slot, notes);
    setDob('');
    setReferralId('');
    setNotes('');
  };

  const handleClose = () => {
    onClose();
    setDob('');
    setReferralId('');
    setNotes('');
  };

  const handleDobChange = (event) => {
    setDob(event.target.value);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const doesDobMatch = () => {
    if (patient.dob === dob) {
      return true;
    }

    return false;
  };

  const isDisabled = () => {
    if (doesDobMatch() && dobConfirmationRequired && !isEmpty(referralId)) {
      return false;
    }

    return true;
  };

  return (
    <Dialog className="confirmation-modal" onClose={handleClose} open={open}>
      <DialogTitle>Appointment Confirmation</DialogTitle>
      {dobConfirmationRequired ? (
        <DialogContent>
          <h4>Patient Information</h4>
          <div className="row">
            <p className="bold">Last Name:</p>
            <p>{patient.lastName}</p>
          </div>
          <div className="row">
            <p className="bold">First Name:</p>
            <p>{patient.firstName}</p>
          </div>
          <div className="row">
            <p className="bold">Date of Birth:</p>
            <TextField placeholder="mm/dd/yyyy" value={dob} onChange={handleDobChange} error={!doesDobMatch()} />
          </div>
          <div className="row">
            <p className="bold">Referral:</p>
            <FormControl size="small" error={!referralId}>
              <Select className="referral" value={referralId} onChange={(event) => setReferralId(event.target.value)}>
                {referrals.map((referral) => (
                  <MenuItem key={referral.id} value={referral.id}>
                    {getProviderName(referral.providerId)} ({getSpecialtyInfo(referral.followupSpecialtyId)?.name})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
      ) : null}
      <DialogContent>
        <h4>Appointment Information</h4>
        <div className="row">
          <p className="bold">Provider:</p>
          <p>{getProviderName(slot.providerId)}</p>
        </div>
        <div className="row">
          <p className="bold">Date:</p>
          <p>{formatDate(slot.date)}</p>
        </div>
        <div className="row">
          <p className="bold">Time:</p>
          <p>{formatTime(slot.startTime)}</p>
        </div>
        <div className="row">
          <p className="bold">Duration:</p>
          <p>{slot.duration} minutes</p>
        </div>
      </DialogContent>
      <DialogContent>
        <TextField className="notes" label="Notes" multiline rows={3} value={notes} onChange={handleNotesChange} />
      </DialogContent>
      <DialogContent className="actions">
        <Button className="cancel" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="confirm" variant="contained" onClick={confirm} disabled={isDisabled()}>
          Confirm
        </Button>
      </DialogContent>
    </Dialog>
  );
}
