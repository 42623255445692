import './style.scss';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { find, isEmpty } from 'lodash';
import React, { useState } from 'react';

export default function ProviderFilter(props) {
  const { providers, selectedProviders, selectProvider } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const providerId = event.target.attributes.provider.value;
    const provider = find(providers, ['id', providerId]);

    selectProvider(provider);
  };

  const isChecked = (provider) => {
    if (find(selectedProviders, ['id', provider.id])) {
      return true;
    }

    return false;
  };

  const selectedCount = (selectedProviders) => {
    if (!isEmpty(selectedProviders)) {
      return <span>({selectedProviders.length})</span>;
    }

    return '';
  };

  return (
    <div className="provider-filter">
      <Button variant="outlined" onClick={openMenu} disabled={providers.length < 1}>
        Providers
        {selectedCount(selectedProviders)}
      </Button>
      <Menu className="table-filter-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {providers.map((provider) => (
          <MenuItem key={provider.id} provider={provider.id} onClick={handleChange}>
            <Checkbox checked={isChecked(provider)} disabled />
            {provider.displayName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
