import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import HiroApi from '../../HiroApi';
import { selectProviders } from '../../state/systemSlice';
import { formatDate, formatTime } from '../../utils/helpers';
import ConfirmationModal from './ConfirmationModal';

export default function MessageAction(props) {
  const { getAccessTokenSilently } = useAuth0();
  const providers = useSelector(selectProviders);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verified, setVerified] = useState('');
  const slot = JSON.parse(props.body.replace(/'/g, '"')).content;
  const { campaignId, referrals } = props;

  const bookAppointment = async (slot, notes) => {
    try {
      setIsBookingLoading(true);

      const token = await getAccessTokenSilently();
      const payload = { ...slot, notes };
      const booked = await HiroApi.bookAppointment(payload, token);

      if (booked) {
        await HiroApi.updateCampaign(campaignId, { actionNeeded: false }, token);
      }

      setIsBookingLoading(false);
    } catch (error) {
      console.log(error);
      setIsBookingLoading(false);
    }
  };

  const verifyAppointment = async (slot) => {
    try {
      setIsVerifying(true);

      const token = await getAccessTokenSilently();
      const verify = await HiroApi.verifyAppointment(slot, token);

      setVerified(verify.valid);
      setIsVerifying(false);
    } catch (error) {
      console.log(error);
      setIsVerifying(false);
    }
  };

  const getProviderName = (providerId) => {
    const provider = providers.find((provider) => provider.id === providerId);

    if (isEmpty(provider)) {
      return '';
    }

    return provider.displayName;
  };

  const handleModalSuccess = (slot, notes) => {
    bookAppointment(slot, notes);
    setConfirmationModalOpen(false);
  };

  const handleModalClose = () => {
    setConfirmationModalOpen(false);
  };

  return (
    <div className="message-action">
      <div className="info">
        <p>{getProviderName(slot.providerId)}</p>
        <p>{slot.ehrId}</p>
        <p>{formatDate(slot.date)}</p>
        <p>{formatTime(slot.startTime)}</p>
        <p>{slot.duration} mins</p>
      </div>

      <div className="actions">
        {isVerifying ? (
          <Loader />
        ) : (
          <div className="button">
            <Button variant="contained" onClick={() => verifyAppointment(slot)}>
              Verify
            </Button>
            {verified ? <CheckIcon className="check" /> : verified === '' ? null : <CloseIcon className="close" />}
          </div>
        )}

        {isBookingLoading ? (
          <Loader />
        ) : (
          <div className="button">
            <Button variant="contained" onClick={() => setConfirmationModalOpen(true)}>
              Book
            </Button>
          </div>
        )}
      </div>

      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={handleModalClose}
        onSuccess={handleModalSuccess}
        slot={slot}
        getProviderName={getProviderName}
        dobConfirmationRequired
        referrals={referrals}
      />
    </div>
  );
}
