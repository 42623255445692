import './style.scss';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUploadFileFilters, setUploadFileFilters } from '../../state/systemSlice';

export default function QueueFilter(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const selectedQueue = useSelector(selectUploadFileFilters).queue;
  const queueOptions = ['all', 'ready', 'pending', 'sent', 'unfit'];

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const selected = event.target.attributes.value.value;

    dispatch(setUploadFileFilters({ queue: selected }));
    setAnchorEl(null);
    props.setPage(1);
  };

  return (
    <div className="queue-filter">
      <Button variant="outlined" onClick={openMenu}>
        Queue: <span>{selectedQueue}</span>
      </Button>
      <Menu className="table-filter-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {queueOptions.map((queue) => (
          <MenuItem key={queue} className="capitalize" value={queue} onClick={handleChange}>
            {queue}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
