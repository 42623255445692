import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import { selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';
import UserDropdown from './UserDropdown';

export default function Navbar() {
  const { isAuthenticated } = useAuth0();
  const userType = useSelector(selectUserType);
  const navigate = useNavigate();

  if (isAuthenticated) {
    return (
      <AppBar className="nav" sx={{ bgcolor: 'white', boxShadow: 3 }} position="sticky">
        <Toolbar>
          <NavLink to="/dashboard">
            <img src={logo} alt="Kouper Health" />
          </NavLink>
          <div className="subtitle">(ED)</div>
          {userType === USER_ROLES.ADMIN ? (
            <Button
              className="link"
              onClick={() => {
                navigate('patients/upload');
              }}
            >
              Upload
            </Button>
          ) : null}
          {userType === USER_ROLES.ADMIN ? (
            <Button
              className="link"
              onClick={() => {
                navigate('reporting');
              }}
            >
              Reporting
            </Button>
          ) : null}
          <UserDropdown />
        </Toolbar>
      </AppBar>
    );
  }
}
