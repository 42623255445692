import './style.scss';

import { useAuth0 } from '@auth0/auth0-react';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';

import HiroApi from '../../HiroApi';

export default function PastPatientAppointments(props) {
  const { id, type } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [collapse, setCollapse] = useState(false);
  const [patientAppointments, setPatientAppointments] = useState([]);

  useEffect(() => {
    fetchPastAppointments(id, type);
  }, []);

  const fetchPastAppointments = async () => {
    const token = await getAccessTokenSilently();
    let appointments;

    if (type === 'patient') {
      appointments = await HiroApi.getPastPatientAppointments(id, token);
    } else if (type === 'campaign') {
      appointments = await HiroApi.getPastAppointments(id, token);
    }

    setPatientAppointments(appointments);
  };

  const appointmentStatusIcon = (status, statusVerbose) => {
    let icon;

    if (status === 'x') {
      icon = <EventBusyOutlinedIcon />;
    } else {
      icon = <EventAvailableOutlinedIcon />;
    }

    return (
      <Tooltip
        title={
          <div
            style={{
              whiteSpace: 'pre-line',
              fontSize: '14px',
            }}
          >
            {statusVerbose}
          </div>
        }
        placement="bottom"
        arrow
      >
        {icon}
      </Tooltip>
    );
  };

  return (
    <div className="patient-appointments section">
      <div className="header">
        <h3>Past Appointments:</h3>
        {collapse ? (
          <IconButton onClick={() => setCollapse(!collapse)}>
            <ExpandMoreIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => setCollapse(!collapse)}>
            <ExpandLessIcon />
          </IconButton>
        )}
      </div>

      {collapse ? null : (
        <div>
          {patientAppointments.length ? (
            <TableContainer>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Time</TableCell>
                    <TableCell align="center">Provider</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">Dept</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientAppointments.map((appt) => (
                    <TableRow key={appt.appointmentId}>
                      <TableCell align="center">{appt.date}</TableCell>
                      <TableCell align="center">
                        {appt.startTime} ({appt.duration} mins)
                      </TableCell>
                      <TableCell align="center">{appt.provider}</TableCell>
                      <TableCell align="center">{appt.appointmentType}</TableCell>
                      <TableCell align="center">{appt.department}</TableCell>
                      <TableCell align="center" className="status">
                        {appointmentStatusIcon(appt.appointmentStatus, appt.appointmentStatusVerbose)}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            <div
                              style={{
                                whiteSpace: 'pre-line',
                                fontSize: '14px',
                              }}
                            >
                              {appt.notes}
                            </div>
                          }
                          placement="bottom"
                          arrow
                        >
                          <TextSnippetOutlinedIcon sx={{ color: appt.notes?.length ? 'black' : 'gray' }} />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <p>Patient does not have any appointments in the past 30 days.</p>
          )}
        </div>
      )}
    </div>
  );
}
