import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { Link } from 'react-router-dom';

function DobTooltip({ patient }) {
  return (
    <Tooltip
      title={
        <div
          style={{
            whiteSpace: 'pre-line',
            fontSize: '14px',
          }}
        >
          Patient is under 2 years old
        </div>
      }
    >
      <span className="orange">{patient.dob}</span>
    </Tooltip>
  );
}

function DobTooltipAndLink({ patient }) {
  return (
    <Tooltip
      title={
        <div
          style={{
            whiteSpace: 'pre-line',
            fontSize: '14px',
          }}
        >
          Patient is under 2 years old
        </div>
      }
    >
      <Link className="orange pointer" to={`/patients/${patient.patientId}`}>
        {patient.dob}
      </Link>
    </Tooltip>
  );
}

function PatientDob({ patient }) {
  if (patient.queue === 'pending') {
    if (patient.isBaby) {
      return <DobTooltipAndLink patient={patient} />;
    }

    return <Link to={`/patients/${patient.patientId}`}>{patient.dob}</Link>;
  }

  if (patient.isBaby) {
    return <DobTooltip patient={patient} />;
  }

  return <span>{patient.dob}</span>;
}

export { DobTooltip, DobTooltipAndLink, PatientDob };
