export const QUEUE_STATUS = {
  READY: 'ready',
  PENDING: 'pending',
  UNFIT: 'unfit',
  SENT: 'sent',
};

export const CONSENT_STATUS = {
  YES: 'yes',
  NO: 'no',
  PENDING: 'pending',
};
