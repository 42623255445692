export const MESSAGE_TEMPLATES = [
  {
    label: 'Consent 1',
    message:
      'Hi, Care Navigator (Kouper) with Frye Care Physician Network here to help book follow-up appts. To proceed please read https://kouper.co/consent and reply Y to continue.\n\nMsg STOP to opt out',
  },
  {
    label: 'Consent 2',
    message: `Hi, Care Navigator (Kouper) with Frye Care Physician Network here to help. Please read https://kouper.co/consent and reply 'Y' to continue.\n\nMsg STOP to opt out`,
  },
  {
    label: 'Consent 3',
    message: `Last Msg: Care Navigator (Kouper) with Frye Care Physician Network. Please read https://kouper.co/consent and reply 'Y' to continue.\n\nMsg STOP to opt out`,
  },
];
