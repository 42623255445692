import './Loader.scss';

import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export default function Loader(props) {
  const size = props.size || 40;

  return (
    <div className="loader" title="Loading">
      <CircularProgress size={size} />
    </div>
  );
}
