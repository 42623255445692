import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  campaigns: [],
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
  },
});

export const { setCampaigns } = campaignSlice.actions;

export default campaignSlice.reducer;

export const selectCampaigns = (state) => state.campaign.campaigns;
