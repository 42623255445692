import './style.scss';

import React, { useState } from 'react';

import CampaignsTable from './CampaignsTable';
import TableFilters from './TableFilters';

export default function Dashboard() {
  const [loading, setLoading] = useState(true);

  return (
    <div className="dashboard">
      <TableFilters loading={loading} setLoading={setLoading} />
      <CampaignsTable loading={loading} />
    </div>
  );
}
