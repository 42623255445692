import './style.scss';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { find } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectQueue, setQueue } from '../../state/systemSlice';
import { PATIENT_QUEUES } from '../../utils/constants/patient-queues';

export default function QueueFilter() {
  const dispatch = useDispatch();
  const queue = useSelector(selectQueue);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    const value = event.target.attributes?.value?.nodeValue;

    if (value) {
      dispatch(setQueue(value));
    }

    setAnchorEl(null);
  };

  const getQueueLabel = () => {
    return find(PATIENT_QUEUES, ['value', queue]).label;
  };

  return (
    <div className="patient-queue-filter">
      <Button variant="outlined" onClick={handleClick}>
        Queue: <p>{getQueueLabel()}</p>
      </Button>
      <Menu className="queue-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {PATIENT_QUEUES.map((q) => (
          <MenuItem key={q.value} value={q.value} onClick={handleClose}>
            {q.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
