import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userName: '',
  userType: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    resetUserState: (state) => {
      state = initialState;
    },
  },
});

export const { setUserName, setUserType, resetUserState } = userSlice.actions;

export default userSlice.reducer;

export const selectUserName = (state) => state.user.userName;
export const selectUserType = (state) => state.user.userType;
