import './style.scss';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

export default function CancellationModal(props) {
  const { appointment, onClose, onSuccess, open } = props;
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {}, []);

  const confirm = () => {
    onSuccess(appointment.id, reason, notes);
  };

  const handleClose = () => {
    onClose();
    setReason('');
    setNotes('');
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.textContent);
  };

  return (
    <Dialog className="confirmation-modal" onClose={handleClose} open={open}>
      <DialogTitle>Confirm Appointment Cancellation</DialogTitle>
      <DialogContent className="appointment-info">
        <FormControl>
          <p>Reason:</p>
          <Select
            className="reason"
            placeholder="Reason"
            value={reason}
            onClick={handleReasonChange}
            error={isEmpty(reason)}
          >
            <MenuItem value="Staff scheduling error">Staff scheduling error</MenuItem>
            <MenuItem value="Patient Rescheduled">Patient Rescheduled</MenuItem>
            <MenuItem value="Patient Cancelled did not want to reschedule">
              Patient Cancelled did not want to reschedule
            </MenuItem>
          </Select>
        </FormControl>
        <TextField label="Notes" multiline rows={3} value={notes} onChange={handleNotesChange} />
      </DialogContent>
      <DialogContent className="actions">
        <Button className="cancel" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="confirm" variant="contained" onClick={confirm} disabled={isEmpty(reason)}>
          Confirm
        </Button>
      </DialogContent>
    </Dialog>
  );
}
